import './makneil_config';
import Request from './request';
import Form from "./request_form";
import Page from "./request_page";
import Template from "./request_template";

import {setQueryParameters, getQueryParameters} from "./utilites/query";
import {formatMoney, formatNum, getNumEnding} from "./utilites/formats";
import formSerializer from "./utilites/serializer";


const send = Request.interface
send.Form = Form.interface
send.Page = Page.interface
send.Template = Template.interface
send.utils = {
    setQueryParameters,
    getQueryParameters,
    formSerializer,
    formatMoney,
    formatNum,
    getNumEnding,
}


export default send