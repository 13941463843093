export function getCSRF() {
    const name = 'csrftoken'
    let cookieValue = null

    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';')

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()

            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }

    return cookieValue
}


export function crossDomain(url) {
    const urlAnchor = document.createElement('a')
    urlAnchor.href = url
    const originAnchor = new URL(location.href)
    return urlAnchor.hostname !== originAnchor.hostname
}


export function csrfSafeMethod(method) {
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method))
}
