import Request from "./request";
import {getElement} from "./utilites/dom";
import formSerializer from "./utilites/serializer";
import {serialize as objectToFormData} from "object-to-formdata";
import merge from 'deepmerge'


const Default = {
    redirect: false,
    resetForm: false,
    recaptcha: false,
    fileUploadSupport: false,
    notifySuccessText: ''
}


class Form {

    constructor(element, config) {
        this.element = element
        this.config = this._getConfig(config)
        // create recaptcha
        if (this.config.recaptcha) {
            if (!window.initRecaptcha) {
                throw 'recaptcha parameters are not specified in the global variable "initRecaptcha"'
            }
            this._recaptchaFieldName = initRecaptcha.fieldName
            this._recaptchaToken = ''
            this._recaptchaWidgetId = 0
            this._initRecaptcha()
        }
        this._addEventListeners()
    }

    _send() {
        const btnSubmit = this.element.querySelector('[type="submit"]')
        
        if (typeof this.config.onBefore === 'function') {
            this.config.onBefore()
        }
        
        new Request({
            method: this.config.method,
            url: this.config.url,
            data: this._getData(),
            onBefore: () => {
                // btn loader
                mk.Loader(btnSubmit).show()
            },
            onSuccess: (data) => {
                if (typeof this.config.onSuccess === 'function') {
                    this.config.onSuccess(data)
                }
                // remove errors fields
                mk.Form(this.element).removeErrorFields()
                // redirect
                if (typeof this.config.redirect === 'string') {
                    window.location.replace(this.config.redirect)
                } else if (typeof this.config.redirect === 'function') {
                    window.location.replace(this.config.redirect(data))
                }
                // reset form
                if (this.config.resetForm) {
                    mk.Form(this.element).reset()
                }
                // notification
                if (this.config.notifySuccessText) {
                    mk.notify('success', this.config.notifySuccessText)
                }
            },
            onError: (error) => {
                if (typeof this.config.onError === 'function') {
                    this.config.onError(error)
                }
                if (error && error.status === 400) {
                    // validate form
                    if (error.data.non_field_errors) {
                        mk.notify('error', error.data.non_field_errors[0])
                    } else if (error.data.detail) {
                        mk.notify('error', error.data.detail)
                    } else {
                        mk.notify('error', 'Пожалуйста, исправьте ошибки в форме.')
                        mk.Form(this.element).addErrorFields(error.data)
                    }
                } else if (error && error.status === 403) {
                    if (error.data.detail) {
                        mk.notify('error', error.data.detail)
                    } else {
                        mk.notify('error', 'У вас недостаточно прав для выполнения данного действия.')
                    }
                } else {
                    mk.notify('error', 'Ошибка сервера. Попробуйте повторить запрос позже.')
                    console.error(error)
                }
            },
            onComplete: (status) => {
                if (typeof this.config.onComplete === 'function') {
                    this.config.onComplete(status)
                }
                // hide loader button
                if (this.config.redirect === false || (this.config.redirect !== false && status !== 'success')) {
                    mk.Loader(btnSubmit).hide()
                }
                // reset recaptcha
                if (this.config.recaptcha){
                    grecaptcha.reset(this._recaptchaWidgetId)
                }
            }
        })
    }

    _getData() {
        const data = merge(formSerializer(this.element), this.config.data)

        if (this.config.recaptcha) {
            data[this._recaptchaFieldName] = this._recaptchaToken
        }

        if (this.config.fileUploadSupport) {
            const inputs = this.element.querySelectorAll('input[type="file"]')
            inputs.forEach(input => {
                if (input.files.length === 0 || !input.name) {
                    return
                }
                if (input.multiple) {
                    if (!data[input.name]) {
                        data[input.name] = []
                    }
                    for (const file of input.files) {
                        data[input.name].push(file)
                    }
                } else {
                    data[input.name] = input.files[0]
                }
            })
            return objectToFormData(data)
        }

        return data
    }

    _initRecaptcha() {
        // add script
        if (!document.querySelector('#_RECAPTCHA_SCRIPT')) {
            const script = document.createElement('script')
            script.id = '_RECAPTCHA_SCRIPT'
            script.src = `https://${initRecaptcha.domain}/recaptcha/api.js?render=explicit`
            document.head.appendChild(script)
        }
        this._createRecaptchaWidget()
    }

    _createRecaptchaWidget() {
        if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.render === 'function') {
            // create block
            const div = document.createElement('div')
            div.classList.add('g-recaptcha')
            div.style.visibility = 'hidden'
            document.body.appendChild(div)
            // render recaptcha
            this._recaptchaWidgetId = grecaptcha.render(div, {
                'sitekey': initRecaptcha.key,
                'size': 'invisible',
                'callback': (token) => {
                    this._recaptchaToken = token
                    this._send()
                }
            })
        } else {
            setTimeout(() => {
                this._createRecaptchaWidget()
            } ,300)
        }
    }

    _getConfig(config) {
        const form = {}
        if (this.element.hasAttribute('method')) {
            form.method = this.element.getAttribute('method')
        }
        if (this.element.hasAttribute('action')) {
            form.url = this.element.action
        }
        return merge.all([Request.Default, Default, form, config])
    }

    _addEventListeners() {
        this.element.addEventListener('submit', event => {
            event.preventDefault()
            if (this.config.recaptcha) {
                grecaptcha.execute(this._recaptchaWidgetId)
            } else {
                this._send()
            }
        })
    }

    static interface(form, config) {
        form = getElement(form)
        if (!form) {
            return
        }
        return new Form(form, config)
    }

}

export default Form