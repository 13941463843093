export function setQueryParameters(name, value, data) {
    const params = new URLSearchParams(window.location.search)
    const state = data || {}

    params.set(name, value)

    window.history.pushState(state, "", decodeURIComponent(window.location.pathname + '?' + params));
}


export function getQueryParameters(name, url) {
    if (!url) {
        url = window.location.href
    }

    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)

    if (!results) {
        return null
    }

    if (!results[2]) {
        return ''
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}