const defaultTypes = {
    "string":  function(str) { return String(str) },
    "number":  function(str) { return Number(str) },
    "boolean": function(str) { return ["false", "null", "undefined", "", "0"].indexOf(str) === -1 },
    "null":    function(str) { return ["false", "null", "undefined", "", "0"].indexOf(str) === -1 ? str : null },
}

const formSerializer = function (form) {

    const inputs = form.querySelectorAll('[name]')

    function applyTypeFunc(name, strVal, type) {
        const typeFunc = defaultTypes[type]
        if (!typeFunc) {
            throw new Error(`serializeJSON ERROR: Invalid type ${type} found in input name ${name}.`)
        }
        return typeFunc(strVal)
    }

    function splitInputName(name) {
        let keys = name.split("[")

        keys = keys.map(function (key) {
            return key.replace(/\]/g, "")
        })

        if (keys[0] === "") {
            keys.shift()
        }

        return keys
    }

    function deepSet(o, keys, value) {

        let key = keys[0]

        // Only one key, then it's not a deepSet, just assign the value in the object or add it to the array.
        if (keys.length === 1) {
            if (key === "") {
                o.push(value)
            } else {
                o[key] = value
            }
            return
        }

        let nextKey = keys[1] // nested key
        let tailKeys = keys.slice(1) // list of all other nested keys (nextKey is first)

        if (key === "") { // push nested objects into an array (o must be an array)
            let lastIdx = o.length - 1
            let lastVal = o[lastIdx]

            // if the last value is an object or array, and the new key is not set yet
            if (isObject(lastVal) && isUndefined(deepGet(lastVal, tailKeys))) {
                key = lastIdx // then set the new value as a new attribute of the same object
            } else {
                key = lastIdx + 1 // otherwise, add a new element in the array
            }
        }

        if (nextKey === "") { // "" is used to push values into the nested array "array[]"
            if (isUndefined(o[key]) || !Array.isArray(o[key])) {
                o[key] = []; // define (or override) as array to push values
            }
        } else {
            if (isUndefined(o[key]) || !isObject(o[key])) {
                o[key] = {}; // define (or override) as object, to set nested properties
            }
        }

        // Recursively set the inner object
        deepSet(o[key], tailKeys, value)
    }

    function deepGet(o, keys) {
        if (isUndefined(o) || isUndefined(keys) || keys.length === 0 || (!isObject(o) && !Array.isArray(o))) {
            return o
        }
        let key = keys[0]
        if (key === "") {
            return undefined
        }
        if (keys.length === 1) {
            return o[key]
        }

        let tailKeys = keys.slice(1)

        return deepGet(o[key], tailKeys)
    }

    function isObject(obj) {
        return obj === Object(obj)
    }

    function isUndefined(obj) {
        return obj === void 0
    }

    function getSelectOptionValue(selectNode) {
        const result = []

        if (!selectNode.multiple) {
            return selectNode.value
        }

        selectNode.querySelectorAll("option").forEach(function (option) {
            if (option.selected) {
                result.push(option.value)
            }
        })

        return result
    }

    function convertToObj() {
        const serializedObject = {}

        inputs.forEach(function (input) {

            let name = input.name
            let type = input.dataset.type
            let value = input.value

            if (!type) {
                if (input.type === 'number') {
                    type = "number"
                } else {
                    type = "string"
                }
            }

            if (input.type === 'file') {
                return
            }

            if (input.type === 'radio' && !input.checked) {
                return
            }

            if (input.type === 'checkbox' && !input.checked) {
                value = 'false'
            }

            if (input.nodeName === 'SELECT' && input.multiple) {
                const values = getSelectOptionValue(input)
                value = values.map(function (val) {
                    return applyTypeFunc(name, val, type)
                })
            } else {
                value = applyTypeFunc(name, value, type)
            }

            const keys = splitInputName(name)

            deepSet(serializedObject, keys, value)
        })

        return serializedObject
    }


    return convertToObj()
}

export default formSerializer