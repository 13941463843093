import Request from "./request";
import {getElement} from "./utilites/dom";
import {getQueryParameters, setQueryParameters} from "./utilites/query";
import templateErrorBox from "./template/boxErrorRequest.html";
import getPageList from "./utilites/paging";
import {render} from "ejs";
import delegate from 'delegate-it'
import merge from 'deepmerge'
import {isPlainObject} from "is-plain-object";


const Default = {
    template: '',
    pagination: {
        element: '',
        template: '',
        urlPage: false,
    },
    adjacent: false,
}


class Template {

    constructor(element, config) {
        this._element = element
        this.config = merge.all([Request.Default, Default, config], {
            isMergeableObject: isPlainObject
        })
        console.log(this.config)
        this._isPagination = !!(this.config.pagination.element && this.config.pagination.template)

        if (this._isPagination) {
            this.config.params.page = parseInt(getQueryParameters('page')) || 1
            this._paginationCont = getElement(this.config.pagination.element)
            this._addEventPagination()
        }

        this._send()
    }

    goToPage(page) {
        this.config.params.page = page
        this._send()
    }

    update() {
        this._send()
    }

    _send() {
        if (typeof this.config.onBefore === 'function') {
            this.config.onBefore()
        }
        new Request({
            method: this.config.method,
            url: this.config.url,
            params: this.config.params,
            onBefore: () => {
                // show loader
                mk.Loader(this._element).show()
            },
            onSuccess: (data) => {
                // render template
                const template = getElement(this.config.template)
                if (!template) throw 'The template is not found.'

                if (this.config.adjacent) {
                    this._element.insertAdjacentHTML(this.config.adjacent, render(template.innerHTML, data))
                } else {
                    this._element.innerHTML = render(template.innerHTML, data)
                }

                // render pagination
                if (this._isPagination) {
                    const template = getElement(this.config.pagination.template)
                    if (!template) throw 'The template pagination is not found.'

                    const dataTemplate = {
                        pagination: getPageList(data.paging.pages, data.paging.page)
                    }

                    this._paginationCont.innerHTML = render(template.innerHTML, merge(dataTemplate, data))
                }

                if (typeof this.config.onSuccess === 'function') {
                    this.config.onSuccess(data)
                }

                this.responce = data
            },
            onError: (error) => {
                if (typeof this.config.onError === 'function') {
                    this.config.onError(error)
                }
                // add box error
                this._element.innerHTML = templateErrorBox
                console.error(error)
            },
            onComplete: (status) => {
                if (typeof this.config.onComplete === 'function') {
                    this.config.onComplete(status)
                }
                // hide loader
                mk.Loader(this._element).hide()
            }
        })
    }

    _addEventPagination() {
        delegate(this._paginationCont, '[data-page]', 'click', event => {
            event.preventDefault()
            let page = parseInt(event.delegateTarget.dataset.page)
            this.config.params.page = page
            if (this.config.pagination.urlPage === true) {
                setQueryParameters('page', page, {'page': page})
            }
            this._element.scrollIntoView()
            this._send()
        })

        if (this.config.pagination.urlPage === true) {
            window.onpopstate = (event) => {
                if(event.state && event.state.page){
                    this.config.params.page = event.state.page
                } else {
                    this.config.params.page = 1
                }
                this._send()
            }
        }
    }

    static interface(element, config) {
        element = getElement(element)
        if (!element) {
            return
        }
        return new Template(element, config)
    }

}

export default Template