export function formatMoney(n) {
    n = Number(n)
    if (Number.isInteger(n)) {
        return this.formatNum(n, 0)
    }
    return this.formatNum(n, 2)
}

export function formatNum(n, decimals) {
    return Number(n).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function getNumEnding(number, endings) {
    //'Результат', 'Результата', 'Результатов'
    let cases = [2, 0, 1, 1, 1, 2]
    return endings[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}