import {csrfSafeMethod, getCSRF} from "./utilites/csrf"


window.mk.defaults.filearea = {
    server: {
        fieldName: 'file',
        onHeader: function (xhr, method) {
            if (!csrfSafeMethod(method) && !xhr.crossDomain) {
                xhr.setRequestHeader("X-CSRFToken", getCSRF())
            }
        },
        onLoad: (response) => response.id,
        onError: (response) => response.file,
    },
}