import {csrfSafeMethod, getCSRF, crossDomain} from "./utilites/csrf";
import merge from 'deepmerge'
import { isPlainObject } from 'is-plain-object';


const Default = {
    method: 'GET',
    url: '',
    data: {},
    params: {},
    onBefore: null,
    onSuccess: null,
    onError: null,
    onComplete: null,
}


class Request {

    constructor(config) {
        this._config = merge(Default, config, {
            isMergeableObject: isPlainObject
        })
        this._controller = new AbortController()
        this.send()
    }

    static get Default() {
        return Default
    }

    send() {
        // before
        if (typeof this._config.onBefore === 'function') {
            this._config.onBefore()
        }

        // set params url
        let url = this._config.url
        if (Object.keys(this._config.params).length > 0) {
            const a = document.createElement('a')
            a.href = this._config.url
            url = new URL(a.href)
            url.search = new URLSearchParams(this._config.params).toString()
        }

        const response = ({})

        fetch(url, this._getRequestOptions())
            .then(res => {
                for (let i in res) {
                    if (typeof res[i] != 'function') response[i] = res[i]
                }
                const ok = res.status >= 200 && res.status < 300
                return res[this._config.responseType || 'text']()
                    .then(data => {
                        response.data = data
                        response.data = JSON.parse(data)
                    })
                    .catch(Object)
                    .then(() => (ok ? response : Promise.reject(response)))
            })
            .then(response => {
                // success
                if (typeof this._config.onSuccess === 'function') {
                    this._config.onSuccess(response.data)
                }
            })
            .catch(error => {
                // error
                if (typeof this._config.onError === 'function') {
                    this._config.onError(error)
                }
            })
            .then(() => {
                // complete
                if (typeof this._config.onComplete === 'function') {
                    this._config.onComplete(response.ok ? 'success' : 'error')
                }
            })
    }

    abort() {
        this._controller.abort()
    }

    _getRequestOptions() {
        const options = {
            method: this._config.method.toUpperCase(),
            credentials: 'same-origin',
            signal: this._controller.signal,
            headers: {}
        }

        // set body
        if (this._config.method.toLowerCase() !== 'get') {
            if (typeof this._config.data === 'object' && typeof this._config.data.append !== 'function') {
                options.body = JSON.stringify(this._config.data)
                options.headers['Content-Type'] = 'application/json'
            } else {
                options.body = this._config.data
            }
        }

        // set csrf token
        if (!csrfSafeMethod(this._config.method) && !crossDomain(this._config.url)) {
            options.headers['X-CSRFToken'] = getCSRF()
        }

        return options
    }

    static interface(config) {
        return new Request(config)
    }
}

export default Request