import Request from "./request";
import {getElement} from "./utilites/dom";
import templateErrorBox from "./template/boxErrorRequest.html";
import merge from 'deepmerge'


const Default = {
    beforeClean: true,
}


class Page {

    constructor(element, config) {
        this._element = element
        this._config = merge.all([Request.Default, Default, config])
        this._send()
    }

    _send() {
        if (typeof this._config.onBefore === 'function') {
            this._config.onBefore()
        }
        new Request({
            method: this._config.method,
            url: this._config.url,
            params: this._config.params,
            onBefore: () => {
                if (this._config.beforeClean) {
                    // clear element
                    this._element.innerHTML = ''
                }
                // show loader
                mk.Loader(this._element).show()
            },
            onSuccess: (data) => {
                this._element.innerHTML = data
                if (typeof this._config.onSuccess === 'function') {
                    this._config.onSuccess(data)
                }
            },
            onError: (error) => {
                if (typeof this._config.onError === 'function') {
                    this._config.onError(error)
                }
                // add box error
                this._element.innerHTML = templateErrorBox
                console.error(error)
            },
            onComplete: (status) => {
                if (typeof this._config.onComplete === 'function') {
                    this._config.onComplete(status)
                }
                // hide loader
                mk.Loader(this._element).hide()
            }
        })
    }

    static interface(element, config) {
        element = getElement(element)
        if (!element) {
            return
        }
        return new Page(element, config)
    }

}

export default Page